/* --------------------------- GLOBAL VARIABLES ---------------------------- */

$colorDark: #1B1B1B;
$colorBase: #242424;
$colorAlternative: #323232;
$colorOther: #404040;
$colorLight: #646464;
$colorLighter: #969696;
$colorAccent: #FF0000;
$colorFont: #FFFFFF;


$navHeight: 56px;
