/* -------------------------------- IMPORTS -------------------------------- */
@import "../../../styles/variables";

/* -------------------------------- MINIGAME ------------------------------- */
.minigame-component {
  .list-group-item {
    background-color: $colorBase;
  }

  .team-icon {
    width: 32px;
    height: 32px;
  }

  // Minigame headings (h4)
  .heading {
    margin: 0;
    padding: .5rem;
    background-color: $colorBase;
  }

  // Table Striped
  .table-striped {
    color: $colorFont;
    font-size: 0.75rem;
    margin-bottom: 0;

    thead tr th,
    tbody tr td {
      border: none;
      padding: .5rem;
    }

    thead tr  {
      background-color: $colorBase;
    }

    tbody tr {
      background-color: $colorOther;

      a {
        color: $colorFont;
      }

      img {
        width: 1rem;
      }
    }
  }

  .table-striped>tbody>tr:nth-child(odd)>td, 
  .table-striped>tbody>tr:nth-child(odd)>th {
    background-color: $colorAlternative;
  }

  // Gun Game
  .gungame-level {
    padding: 0;

    .gungame-level-header {
      padding: .25rem;

      h6 {
        margin: 0;
      }

      a {
        color: $colorLighter;
        text-decoration: none;
      }
    }

    .item-icon {
      width: 64px;
    }

    .fnmod {
      width: 32px;
      height: 32px;
      background-color: black;
      margin: .2rem;
    }

    .gungame-next-level {
      padding: .25rem;
      background-color: $colorAlternative;
    }
  }
}