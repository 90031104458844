/* -------------------------------- IMPORTS -------------------------------- */
@import "../../../styles/variables";


/* --------------------------- DISTRICTS COMPONENT ------------------------- */
.district-component {
  .sidebar {
    padding: 1rem;
    background-color: $colorBase;
    box-shadow: 0px 0px 6px #000000;

    .header {
      text-shadow: 2px 2px 2px #000000;
    }

    .accordion .card {
      background-color: transparent;
      border: none;

      .card-body, .card-header {
        background-color: transparent;
        padding: 0;
        border: none;

        .waypoint-column .fas:hover {
          cursor: pointer;
        }

        .contact-column {
          width: 32px !important;
        }

        .contact-icon {
          width: 24px;
          height: 24px;
        }

        a {
          color: $colorFont;
        }
      }

      /* Accordion order and carrot functionality */
      .card-header {
        order: 1;
        cursor: pointer;
      }

      .collapse, .collapsing {
        order: 2;
      }

      .card-header:after {
        color: $colorFont;
        top: 0;
        right: .1rem;
        position: absolute;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f107";
      }

      .collapse.show + .card-header::after {
        content: "\f106";
      }
    }
  }
}
