/* -------------------------------- IMPORTS -------------------------------- */
@import "../../../styles/variables";

/* ----------------------------- HEAT COMPONENT ---------------------------- */
.rating-component {
  padding: 0px 6px 0px 6px;

  @media (max-width: 1200px) {
    padding: 0;
  }

  table {
    background-color: $colorBase;

    thead tr th {
      color: $colorFont;
      border: none;
    }

    tbody tr td {
      color: $colorFont;
      border-color: $colorAlternative;
      vertical-align: middle;
    }
  }
}