/* -------------------------------- IMPORTS -------------------------------- */
@import "../../../styles/variables";


/* --------------------------- DISTRICTS COMPONENT ------------------------- */
.districts-component {
  overflow: hidden;

  .district-col {
    position: relative;
    height: calc((100vh - #{$navHeight})/2);
    overflow: hidden;

    img {
      /* Cover whole area */
      width: 100%;
      height: 100%;

      /* Add blur effect */
      filter: blur(2px);
      -webkit-filter: blur(2px);

      /* Zoom transition timer */
      transition: transform 1.5s ease;

      /* Don't stretch images */
      object-fit: cover;
    }

    .centered {
      color: $colorFont;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-shadow: 2px 2px 2px #000000;
    }
  }

  /* Zoom into images */
  .district-col:hover img {
    transform: scale(1.25);
  }

  @media only screen and (min-width: 992px) {
    .vertical:hover img {
      transform: scale(1.40) skewX(8deg);
    }

    .last:hover img {
      transform: scale(1.3525) skewX(6deg);
    }

    /* Vertical second row */
    .vertical {
      transform: skewX(-8deg);
      left: -2rem;

      h2, p {
        transform: skewX(8deg);
      }

      img {
        transform: skewX(8deg) scale(1.15);
      }
    }

    /* Last item in row */
    .last {
      transform: scaleX(1.175) skewX(-6deg);

      h2, p {
        transform: skewX(6deg) scaleX(.8025);
      }

      img {
        transform: skewX(6deg) scale(1.1025);
      }
    }
  }
}
