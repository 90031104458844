/* -------------------------------- IMPORTS -------------------------------- */
@import "../../../styles/variables";

/* --------------------------- CONTACTS COMPONENT -------------------------- */
.contact-component {
  // Card changes for levels and unlocked
  .contact-levels, .contact-unlocked {
    margin-bottom: .5rem;

    .card-header {
      padding: .5rem;
    }

    .card-body {
      padding: 0;
    }
  }

  // Contact Levels Table
  .contact-levels .card table {
    color: $colorFont; 
    text-align: left;
    margin: 0;

    td, th {
      border: none;
    }
  }

  // Contact Unlock Card
  .contact-unlocked .card-body {
    .ref {
      padding: .25rem;
    }

    .ref:hover {
      background-color: $colorOther;
    }

    img {
      max-width: 32px;
    }

    a {
      color: $colorFont;
      text-decoration: none;
    }
  }

  .contact-picture {
    max-height: 500px;
  }
  
  @media (max-width: 768px) {
    // Reduce contact picture size on small devices
    .contact-picture {
      width: 200px;
      height: 200px;
      object-fit: cover;
      object-position: 20% 0px; 
    }

    // Add margin to levels table
    .contact-levels {
      margin: .5rem 0 .5rem 0;
    }
  }

  // Contact card
  .contact-card {
    border-color: $colorOther;
    border-radius: 0;

    .card-header {
      background-color: $colorOther;
    }

    .card-body {
      background-color: $colorAlternative;
    }
  }
}
