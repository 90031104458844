/* -------------------------------- IMPORTS -------------------------------- */
@import "../../../styles/variables";

/* --------------------------- CONTACTS COMPONENT -------------------------- */
.contacts-component {
  .organisation-icon {
    height: 64px;
    width: 64px;
  }

  .contact-tree {
    min-width: 1280px;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;

    table {
      width: 100%;

      thead tr th,
      tbody tr td {
        text-align: center;
        vertical-align: middle;
      }

      thead tr th {
        color: gray;
        padding-bottom: 1rem;
      }
    }
  }

  .contact-col {
    a {
      text-decoration: none;
    }

    figcaption {
      color: $colorFont;
      font-size: 1rem;
      text-shadow: 0 0 4px rgba($color: black, $alpha: .5);
    }

    img {
      margin: 0;
    }

    .contact-img {
      width: 140px;
      height: 90px;
      box-shadow: 0 0 8px rgba($color: black, $alpha: .5);
      transition: .5s;
    }

    .contact-img:hover {
      box-shadow: 0 0 8px rgba($color: gray, $alpha: .5);
    }

    .org-img {
      max-width: 64px;
      max-height: 64px;
      filter: drop-shadow(0 0 2px black);
    }
  }
  
  // Fullscreen page when below 1600px
  @media (max-width: 1600px) {
    .container {
      max-width: 100%;
    }
  }

  // Make contacttree scrollable when below 1200px
  @media (max-width: 1200px) {
    // TODO
  }
}
