/* -------------------------------- IMPORTS -------------------------------- */
@import "../../../styles/variables";


/* ----------------------------- ADMIN COMPONENT --------------------------- */
.admin-component {
  .row .dashboard-column {
    padding: 1rem;
    background-color: $colorBase;
    border: 1px solid $colorAlternative;

    /* File Input */
    .custom-file-label,
    .custom-file-label::after  {
      color: $colorFont;
      border: none;
      border-radius: 0;
    }

    .custom-file-label {
      background-color: $colorAlternative !important;
    }
    
    .custom-file-label::after {
      background-color: $colorOther;
      height: 38px;
    }

    /* Clean Cards */
    .card {
      background-color: $colorAlternative;

      .card-header {
        padding: .5rem;
      }
    }
  }
}
