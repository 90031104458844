/* -------------------------------- IMPORTS -------------------------------- */
@import "./styles/variables";

/* ------------------------------ APB DATABASE ----------------------------- */

body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  margin-top: $navHeight;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $colorFont;
  background: $colorAlternative;
}

/* ---------------------------- CONTAINER FLUID ---------------------------- */
.container-fluid {
  padding: 0;
}

/* ------------------------------- CONTAINER ------------------------------- */
// Extend container size for large displays
@media (min-width: 1200px) {
  .container {
    max-width: 66.66666666666667vw;
  }
}

// Increase width to maxiumum on small devices
@media (max-width: 1200px) {
  .container {
    max-width: 100%;
  }
}

/* -------------------------------- HEADERS -------------------------------- */
h1, h2, h3, h4, h5 {
  font-weight: 400;
}

/* ------------------------------ FORM INPUT ------------------------------- */
.form-control {
  color: $colorFont;
  background-color: $colorAlternative;
  border: none;
  border-radius: 0;
  color: $colorFont;
  height: 36px;
}

.form-control:focus,
.form-control:hover {
  color: $colorFont;
  box-shadow: none;
  background-color: $colorOther;
}


/* ------------------------------- ITEM TABLE ------------------------------ */
.item-table {
  color: $colorFont;

  .small-width {
    width: 5%;
  }

  thead tr th {
    border: none;
    background: $colorAlternative;
  }

  tbody { 
    tr {
      border: none;
      background: $colorOther;

      td {
        border-color: $colorBase;
        vertical-align: middle;
      }

      a {
        color: $colorFont;
      }        
      
      img {
        max-height: 32px;
        max-width: 32px;
      }
    }
  }
}


/* -------------------------------- TABLE LITE ----------------------------- */
.table-lite {
  thead tr th {
    border: none;
    color: $colorFont;
    padding: 0px 5px 5px 5px;
  }

  tbody { 
    tr td {
      border: none;
      color: $colorFont;
      padding: 0px 5px 3px 0px;

      .modified {
        color: yellow;
      }
    }

    tr td.reduced {
      word-wrap: break-word;
      min-width: 200px;
      max-width: 200px;
    }

    tr:hover {
      background-color: $colorAlternative;
    }
  }
}

/* ------------------------------- FORM GROUP ------------------------------ */
.form-group {
  .form-control {
    color: $colorFont;
    background: $colorOther;
    border: none;
    border-radius: 0;
  }
}


/* ------------------------------ INPUT GROUP ------------------------------ */
.input-group {
  .input-group-prepend .input-group-text {
    color: $colorFont;
    background: $colorOther;
    border: none;
    border-radius: 0;
  }
}

/* --------------------------- BOOTSTRAP BUTTONS --------------------------- */
.btn {
  border-radius: 0;
  border: none;
}

.btn-secondary {
  background-color: $colorAlternative;
}

.btn-secondary:hover {
  background-color: $colorAlternative;
  filter: brightness(125%);
}

/* ------------------------------ BUTTON GROUP ----------------------------- */
.button-clear {
  background: $colorBase;
  color: $colorFont;
  border: none;
  border-radius: 0;
  padding: 1rem;
  transition: .25s;
}

.button-clear:hover {
  background: $colorAlternative;
}

.button-clear:focus {
  outline: none;
}

/* ----------------------------- FORM CHECKBOX ----------------------------- */
// from: https://codepen.io/spacemonkey/pen/vmZROv
.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: $colorOther;
  }

  // Box hover
  &:hover + label:before {
    background: $colorLight;
  }
  
  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked + label:before {
    background: $colorLight;
  }
  
  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: $colorOther;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 
      2px 0 0 white,
      4px 0 0 white,
      4px -2px 0 white,
      4px -4px 0 white,
      4px -6px 0 white,
      4px -8px 0 white;
    transform: rotate(45deg);
  }
}

/* --------------------------------- MODAL --------------------------------- */
.modal-content {
  background-color: $colorAlternative;
  box-shadow: 0px 0px 12px #111111;
  border: none;

  .modal-header,
  .modal-footer {
    border-color: $colorBase;
  }
}

/* ---------------------------------- CODE --------------------------------- */
code {
  background-color: $colorBase;
  border: 2px $colorBase;
  padding: 2px;
  border-radius: 4px;
}

/* ---------------------------------- CARD --------------------------------- */
.card {
  background-color: $colorAlternative;
  border-radius: 0;
}

/* ------------------------------- LIST GROUP ------------------------------ */
.list-group {
  border-radius: 0;
}

/* ---------------------------- FLIPPED ACCORDION -------------------------- */
.flipped-accordion {
  // Turn order around again
  .flipped-header {
    order: 1;
  }

  .flipped-collapse {
    order: 2;
  }

  // Icon
  .flipped-header::after {
    color: $colorFont;
    top: .75em;
    right: .75em;
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f107";
  }

  .flipped-collapse.show + .flipped-header::after {
    content: "\f106";
  }
}

/* --------------------------------- OTHER --------------------------------- */

/* Centers content to center of screen; minus navigation! */
.screen-center {
  min-height: calc(100vh - #{$navHeight});
  
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Fake link hover styling; might need to be redone.. */
.fake-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* Set an image to the screen background */
.background-image {
  position: fixed; 
  right: 0; 
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
  width: auto; 
  height: auto; 
  z-index: -100;

  /* Center background */
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

/* Set the opacity for an element */
.opacity-5 {
  opacity: 5%;
}

/* Remove arrows inside number inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

/* Properly wraps p elements */
.pre-wrap {
  white-space: pre-wrap;
  margin-bottom: 0;
}

.debug {
  color: #F28C28;
}

/* support me text on the home page */
.support-text {
  text-shadow: #000 0px 0px 3px;
  
  a {
    color: $colorFont;
  }
}