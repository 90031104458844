/* ------------------------- IMPORTS ------------------------ */
@import "../../styles/variables";

/* ------------------------ VARIABLES ----------------------- */
$defaultPadding: 1rem;

/* ------------------ ITEM DETAIL COMPONENT ----------------- */
.detail-component {
  border-left: 1px $colorBase solid;
  background-color: $colorOther;

  .header {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: $colorBase;
    
    img {
      margin-left: 1rem;
      margin-right: 1rem;
      max-width: 48px;
      max-height: 48px;
    }
  }

  .fnmod {
    background: transparent;
    border: none;
    padding: 0;
    outline: none;

    img {
      margin: .5rem;
      background: black;
      max-width: 48px;
      max-height: 48px;
    }
  }

  .description {
    background-color: $colorOther;
    padding-top: $defaultPadding !important;
    padding-bottom: $defaultPadding !important;

    ul {
      padding-inline-start: 30px;

      a {
        color: $colorFont;
      }
    }

    .kill-icon {
      max-height: 16px;
    }
  }
  
  /* ITEM DETAIL */
  .item-detail-tabs {
    nav {
      background-color: $colorAlternative;
      border-bottom: 1px solid $colorAlternative;
      font-size: .85rem;
      font-weight: 500;
      
      .nav-item {
        color: $colorLighter;
        background: transparent;
        border: none;
        border-radius: 0;
        border-bottom: 4px solid transparent;
        transition: .25s linear;
      }

      .nav-item.active {
        color: $colorAccent;
        border-bottom: 4px solid $colorAccent;
      }

      .nav-item:focus {
        outline: none;
      }

      .nav-item.disabled {
        filter: brightness(50%);
      }

      .nav-item:hover {
        filter: brightness(150%);
      }
    }

    .tab-content {
      background-color: $colorOther;

      .row {
        padding: 1rem 0 1rem 0;
      }
    }
  }

  .row {
    margin: 0;
    padding: 0;
  }

  .stats-header {
    background: $colorBase;
    padding: .5rem;
    margin: 0;
  }

  .stats-table {
    margin-bottom: 0;
    background-color: $colorOther;
    
    tr {
      border-top: .25px solid $colorBase;

      td {
        padding: .25rem .5rem .25rem .5rem;
      }
    }
  }

  /* ListGroup styling for related/ searched items in Compare */
  .list-group-small {
    .list-group-item {
      background-color: transparent;
      border: none;
      border-radius: 0;
      padding: .25rem;
      
      img {
        width: 24px;
        height: 24px;
      }
    }

    .list-group-item:hover {
      background-color: $colorLight;
      cursor: pointer;
    }
  }

  // Modding Tab
  .modding-tab {
    .fnmod-droppables {
      text-align: center;
    }

    /* Changes to Font Awesome Icons */
    .reset:hover {
      filter: brightness(75%);
      cursor: pointer;
    }
  }
  
  // Compare Tab
  .compare-tab {
    .compare-search {
      font-size: small;
      height: 32px;
    }

    /* Override global input styling for compare search */
    .compare-search:hover,
    .compare-search:focus {
      background-color: $colorAlternative;
    }

    /* Changes to Font Awesome Icons */
    .reset:hover {
      filter: brightness(75%);
      cursor: pointer;
    }

    .fa-chevron-up {
      color: green;
    }

    .fa-chevron-down {
      color: red;
    }
  }
  

  /* MISSION DETAIL */
  .collapse-buttons {
    width: 100%;
    background-color: $colorOther;

    button {
      background: transparent;
      color: $colorFont;
      border: none;
      padding: 0.5rem;
      padding-right: 1rem;
      text-shadow: 0px 0px 2px black;
    }

    button:hover {
      filter: brightness(75%);
    }

    button:focus {
      outline: none;
    }
  }

  .mission-stage {
    border: none;
    
    .stage-header {
      padding-left: 1rem;
      border-bottom: 2px $colorOther solid;
      cursor: pointer;

      h6 {
        margin: 0;
      }
    }

    .stage-collapse {
      background-color: $colorOther;
      
      .row {
        padding-top: 1rem;
        padding-bottom: 1rem;  

        p {
          margin: 0;
        }
      }
    }

    .stage-header::after {
      top: 1.25rem;
      right: 1rem;
    }
  }

  .ul-columns-2 {
    column-count: 2;
    column-rule: none;
    padding-inline-start: 20px !important;
  }
}

/* Support for mobile devices */
@media only screen and (max-width: 768px) {
  .detail-component {
    position: fixed !important;
    top: $navHeight;
    left: 0;
    z-index: 15;
    width: 100vw;
    height: 100vh;

    .header h4 {
      font-size: 1rem;
    }
  }
}
