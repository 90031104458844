/* ------------------------- IMPORTS ------------------------ */
@import "./variables";

/* ------------------------ VARIABLES ----------------------- */
$searchSize: 3.5rem;
$transitionTime: .25s;

/* ------------------ NAVIGATION COMPONENT ------------------ */
.home-component {
  /* Logo */
  .logo {
    max-width: 480px;
    padding-top: 5vh;
    padding-bottom: 2rem;
    filter: drop-shadow(0 0 6px #222);
  }

  /* Adjust logo size and padding on small devices */
  @media only screen and (max-width: 767px) {
    .logo {
      max-width: 60%;
    }
  }

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  /* Search input */
  .input-group {
    border: 2px solid $colorOther;
    box-shadow: 0px 0px 4px #111111;
    max-width: 700px;

    .form-control {
      height: $searchSize;
      transition: $transitionTime;
    }
    
    .form-control:focus {
      background-color: $colorOther;
    }
    
    .form-control:hover {
      background-color: $colorOther;
    }
  }

  /* List group*/
  .list-group {
    font-size: .9rem;
    width: 100%;

    .list-group-item {
      color: $colorFont;
      background-color: $colorBase;
      padding: .375rem 1.25rem;
      border: none;
      border-radius: 0;
      transition: $transitionTime;
    }

    .list-group-item:hover {
      background-color: $colorAlternative;
      cursor: pointer;
      text-decoration: none;
    }

    /* header for list groups */
    .header.list-group-item {
      color: gray;
      padding: .375rem .75rem;
      background-color: $colorBase;
    }

    .more {
      color: gray;
    }
  }

  /* Search button */
  .btn-search {
    color: $colorFont;
    background: $colorOther;
    border: none;
    border-radius: 0;
    width: $searchSize;
    height: $searchSize;
    outline: none;
    transition: $transitionTime;
  }
}


  
