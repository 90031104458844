/* ------------------------------- IMPORTS --------------------------------- */
@import "../../../styles/variables";


/* -------------------------- TRACKER COMPONENT ---------------------------- */
.tracker-component {
  .accordion {    
    .card {
      background-color: $colorAlternative;

      /* Bring image, heading and subtitles inline */
      .card-img-left {
        float: left;
        margin-right: 1rem;
      }

      .card-title {
        font-size: 1rem;
        margin-bottom: .5rem;
        position: relative;

        a {
          color: $colorFont;
        }
      }

      .card-subtitle {
        font-size: .8rem;

        a {
          color: #6c757d;  // text-muted color
        }
      }

      .card-body {
        /* Post content adjustments */
        .ipsEmoji {
          width: 16px;
          height: 16px;
        }

        .ipsHr,
        .ipsComment_meta,
        .ipsReact_reactions {
          display: none;
        }

        /* Quotes */
        .ipsQuote {
          background-color: $colorAlternative;
          border: 1px solid black;

          .ipsQuote_citation {
            background-color: $colorOther;
          }

          .ipsQuote_citation,
          .ipsQuote_contents {
            padding: .5rem;
          }
        }
      }
    }

    .selected,
    .card:hover {
      background-color: $colorOther;
    }
  }

  /* RSS / Discord Icon */
  .fa-discord,
  .fa-rss-square {
    font-size: 1.5rem;
    color: gray;
    padding-right: .5rem;
  }

  .fa-discord:hover{
    color: #738ADB;
    cursor: pointer;
  }

  .fa-rss-square:hover {
    color: #EE802F;
    cursor: pointer;
  }
}