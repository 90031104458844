/* ------------------------- IMPORTS ------------------------ */
@import "./variables";

/* ------------------ PROGRESSION COMPONENT ----------------- */
.progression-component {
  .background {
    position: fixed; 
    right: 0; 
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
    width: auto; 
    height: auto; 
    z-index: -100;
  
    /* Center background */
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .container-fluid {
    padding: 0;
  }

  .row {
    margin: 0;
    padding: 0;
    height: calc(100vh - #{$navHeight});
  }

  .vertical-center {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(0%, -50%);
    -webkit-transform: translate(0%, -50%);
  }

  .faction-area {
    h1, a {
      color: grey;
    }
  }

  .faction-area:hover {
    h1 {
      color: black;
    }
  }

  /* Faction Links */
  .list-group-item  {
    background: transparent;
    border: none;
    padding-left: 0;

    a span {
      font-size: 14pt;
      font-weight: 500;
      margin-left: 1vh;
    }

    a:hover {
      color: black;
      text-decoration: none;
      cursor: pointer;
      transition: color 250ms linear;
    }

    div {
      display: inline-block;
    }
  }
}
