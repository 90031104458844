/* ------------------------------- IMPORTS --------------------------------- */
@import "../../../styles/variables";


/* --------------------------- ABOUT COMPONENT ----------------------------- */
.about-component {
  a {
    color: $colorFont;
  }

  .header-text {
    h1 span {
      border-bottom: 2px solid red;
    }
  }

  @media (max-width: 767px) {
    img {
      display: none;
    }
  }
}
