/* -------------------------------- IMPORTS -------------------------------- */
@import "../../../styles/variables";


/* ----------------------------- OTHER COMPONENT --------------------------- */
.other-component {
  .sidebar {
    background-color: $colorBase;
    position: fixed;
    z-index: 1030;
    padding: 0;

    .content {
      height: calc(100vh - #{$navHeight});

      .list-group-item {
        color: $colorFont;
        background-color: $colorBase;
        border: none;
        outline: none;
        margin: 0;
        transition: 0.5s;
      }

      .list-group-item.active {
        background-color: $colorAlternative;
      }
    }
  }

  .sidebar-mobile {
    display: none;
  }

  .content {
    padding: 0;

    .constants-table {
      color: $colorFont;
      margin-bottom: 0;

      thead {
        position: sticky;
        top: 0;
  
        tr td {
          font-weight: 500;
          border-top: none;
          padding: 12px;
          background-color: $colorBase;
        }
      }

      tbody tr {
        border-bottom: 1px $colorBase solid;

        td {
          border: none;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .sidebar {
      display: none;
    }

    .sidebar-mobile {
      display: inline;
    }
  }
}
