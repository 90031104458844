/* -------------------------------- IMPORTS -------------------------------- */
@import "./variables";

/* ------------------------------ WIDE HEADER ------------------------------ */
.wide-header {
  background-color: $colorDark;
  padding: .5rem;

  h2 {
    margin: 0;
  }

  img {
    max-width: 56px;
    max-height: 56px;
  }
}

/* ---------------------------- WIDE DESCRIPTION --------------------------- */
.wide-description {
  padding: .5rem;
}

/* -------------------------------- WIDE TABS ------------------------------ */
// Tabs
.wide-tabs {
  // Tabs
  .nav-tabs  {
    border: none;

    .nav-link {
      color: $colorFont;
      font-size: 1.4rem;
      filter: brightness(75%);
      transition: linear .25s;
    }
  
    .nav-link.active,
    .nav-link:hover {
      filter: brightness(100%);
      border-color: transparent;
      background-color: transparent;
    }
  
    .nav-link.disabled {
      filter: brightness(50%);
    }
  }

  // Wide Tab-Table
  table {
    color: $colorFont;
  
    .small-width {
      width: 5%;
    }
  
    thead tr th {
      border: none;
      background: $colorAlternative;
    }
  
    tbody { 
      tr {
        border: none;
        background: $colorAlternative;
  
        td {
          border-color: $colorBase;
          vertical-align: middle;
        }
  
        a {
          color: $colorFont;
        }        
        
        img {
          max-height: 32px;
          max-width: 32px;
        }
      }
    }
  }
}
