/* -------------------------------- IMPORTS -------------------------------- */
@import "../../../styles/variables";


/* --------------------------- CHANGELOG COMPONENT ------------------------- */
.changelog-component {
  h1 {
    text-shadow: 1px 1px 4px #000000;
  }

  .accordion {
    margin-bottom: .5rem;
  
    .card {
      background-color: $colorBase;
      border-radius: 0;

      .card-body {
        background-color: $colorAlternative;
      }

      table {
        // Font color
        thead tr th,
        tbody tr td,
        tbody tr td a {
          border-color: $colorBase;
          color: $colorFont;
          font-size: 0.9rem;
        }
      }
    }
  }

  .new-entry, .new-entry:hover {
    background-color: rgba(0, 255, 0 , .1) !important;
  }

  .deleted-entry, .deleted-entry:hover {
    background-color: rgba(255, 0, 0, .1) !important;
  }

  .modified-entry, .modified-entry:hover {
    background-color: rgba(240, 255, 0, .1);
  }

  // Increase container width < 1200px
  @media (max-width: 1200px) {
    max-width: 1200px;
  }

  // Reduce font size
  @media (max-width: 767px) {
    font-size: .75rem;

    h1 {
      font-size: 3rem;
    }
  }
}