/* ------------------------------- IMPORTS --------------------------------- */
@import "./variables";


/* ----------------------- FILTERTEMPLATE COMPONENTS ----------------------- */
.filter-sidebar {
  background-color: $colorBase;
  position: fixed;
  z-index: 1030;

  .sidebar-content {
    padding: 1rem;
    padding-bottom: 2rem;
    height: calc(100vh - #{$navHeight});

    .input-group {
      margin-bottom: 1rem;
    }

    .form-control[type=number] {
      text-align: center;
    }

    .fa-sync, 
    .fa-chevron-left,
    .fa-chevron-right {
      transition: .25s;
    }

    .fa-sync:hover,
    .fa-chevron-left:hover {
      filter: brightness(75%);
      cursor: pointer;
    }
  }

  select {
    width: 100%;
    background-color: $colorAlternative !important;
  }

  select:hover {
    background-color: $colorOther !important;
  }

  .sidebar-footer {
    position: sticky;
    bottom: 0;
    width: 100%;
    height: 36px;
    background-color: rgba(0, 0, 0, .25);
    padding: .5rem;
  }

  .filter-toggle-mobile {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    z-index: inherit;
    color: $colorFont;
  }

  .filter-apply-button {
    display: none;
    width: 100%;
  }

  /* When collapse */
  .sidebar-content:hover > .fa-chevron-right {
    filter: brightness(75%);
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
}

/* Hide sidebar by default for mobile devices */
@media only screen and (max-width: 767px) {
  .filter-sidebar .sidebar-content,
  .filter-sidebar .sidebar-footer {
    display: none;
  }

  .filter-toggle-mobile {
    display: block !important;
  }

  .filter-apply-button {
    display: block !important;
  }
}

/* ----------------------------- FILTER FOOTER ----------------------------- */
.filter-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 36px;
  background-color: $colorBase;
  z-index: 5;

  .pagination {
    margin: 0;

    .page-item {
      margin:0;
      padding:0;

      span {
        color: $colorFont;
        background-color: $colorBase;
        border: none;
        border-radius: 0;
        margin:0;
      }

      input {
        max-width: 64px;
        text-align: center;
      }
    }

    .page-item span:hover {
      background-color: $colorAlternative;
      cursor: pointer;
    }
  }

  /* on compatct mode, extend pagination */
  .pagination-detail {
    width: 100%;
  
      .page-item {
        width: 100%;
        
        input {
          max-width: 100%;
        }
      }
  }

  /* set pagination to 100% for mobile devices */
  @media only screen and (max-width: 767px) {
    .pagination {
      width: 100%;
  
      .page-item {
        width: 100%;
        
        input {
          max-width: 100%;
        }
      }
    }
  }
}

/* ------------------------------ FILTER TABLE ----------------------------- */
.filter-table {
  color: $colorFont;
  margin-bottom: 0;

  .button-link {
    color: $colorFont;
    border: none;
    background: none;
    padding: 0;
  }

  .button-link:focus {
    outline: none;
  }

  thead tr th {
    background-color: $colorBase;
    border: none;
    padding-left: 8px;
  }

  tbody {
    tr { 
      border-bottom: 1px $colorBase solid;

      td {
        height: 64px;
        padding: .5rem;
        border: none;
      }
      
      .icon {
        width: 5%;
        text-align: center;

        img {
          max-width: 48px;
          max-height: 48px;
        }
      }
    }
    
    .selected,
    tr:hover {
      background-color: $colorOther;
    }
  }
}