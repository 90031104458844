/* ------------------------- IMPORTS ------------------------ */
@import "../../../styles/variables";


/* ------------------- MISSIONS COMPONENT ------------------- */
.missions-component {
  .input-group {
    margin-bottom: 1rem;
  }

  .form-control {
    background-color: $colorAlternative;
    border: none;
    border-radius: 0;
    color: $colorFont;
    height: 36px;
  }

  .form-control:focus,
  .form-control:hover {
    box-shadow: none;
    background-color: $colorOther;
  }

  .mission-table {
    color: $colorFont;
    margin-bottom: 0;

    a {
      color: $colorFont;
    }

    thead {
      position: sticky;
      top: 0;

      tr td {
        font-weight: 500;
        border-top: none;
        padding: .5rem;
        background-color: $colorBase;
      }
    }

    tbody {
      tr { 
        border-bottom: 1px $colorBase solid;

        td {
          height: 48px;
          padding: .5rem;
          border: none;
        
          img {
            max-width: 48px;
            max-height: 48px;
          }
        }
        
        .item-icon {
          width: 5%;
        }
      }
      
      .selected,
      tr:hover {
        background-color: $colorOther;
      }
    }

    .faction-icon img {
      max-width: 18px;
      max-height: 18px;
    }
  }
}
