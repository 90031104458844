/* -------------------------------- IMPORTS -------------------------------- */
@import "../../../styles/variables";

/* ---------------------------- MEDALS COMPONENT --------------------------- */
.medals-component {
  .medal {
    background-color: $colorBase;
    margin: .5rem;
    padding: .5rem;
    box-shadow: 0 0 3px black;

    .header {
      img {
        float: left;
        margin: .5rem .5rem .5rem 0;
      }

      h5, small {
        position: relative;
      }
    }
  }

  h5 {
    margin-bottom: 0;
  }
}