/* -------------------------------- IMPORTS -------------------------------- */
@import "../../../styles/variables";

/* -------------------------- MINIGAMES COMPONENT -------------------------- */
.minigames-component {
  padding-top: 2rem;

  [class^=col-] {
    padding-bottom: 2rem;

    a {
      color: $colorFont;
      text-decoration: none;
    }

    .minigame-border {
      position: relative;
      width: 100%;
      padding: 1rem;
      border: 1px solid $colorOther;
      background-color: $colorAlternative;

      .content {
        height: 300px;
        background-color: $colorOther;
        background-position: center;
        background-size: cover;
        opacity: .8;
        transition: .75s;
      }

      .content:hover {
        transform: scale(1.05);
      }

      h4 {
        left: .75rem;
        bottom: 0;
        position: absolute;
        text-shadow: 0px 0px 8px black;
      }
    }
  }
}
