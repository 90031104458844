/* ------------------------- IMPORTS ------------------------ */
@import "./variables";


/* ------------------ NAVIGATION COMPONENT ------------------ */
.navigation-component {
  .navbar-brand {
    max-height: $navHeight;
    background-color: $colorDark;
    font-size: 1rem;
    padding: 1rem;
    margin-right: 0;
  }

  .navbar-brand-dev {
    background: repeating-linear-gradient(
      45deg,
      #D27D2D,
      #D27D2D 10px,
      #F28C28 10px,
      #F28C28 20px
    );
  }

  .icon {
    width: 1.25rem;
    height: 1.25rem;
    filter: brightness(50%);
    transition: filter .25s linear;
  }

  .navbar-collapse {
    background-color: $colorBase;
  }

  .navbar {
    height: $navHeight;
    max-height: $navHeight;
    background-color: $colorBase !important;
    box-shadow: 0px 0px 2px #000000;

    .nav-link {
      padding: 1rem;
      transition: color .25s linear;
    }

    /* Toggler modifications (mobile) */
    .navbar-toggler {
      border: none;
      transition: color .25s;
      padding: .8rem;
    }

    .navbar-toggler:hover span {
      filter: brightness(75%);
    }

    .navbar-toggler:focus {
      border: none;
      outline: none;
    }

    /* Set link color to default */
    a {
      color: $colorFont;
    }

    /* Changes brightness of icons if active */
    .nav-link.active .icon,
    .nav-link:focus .icon {
      filter: brightness(100%);
    }

    /* Changes brightness of icons if not active but hovered */
    .nav-link:hover:not(.active) .icon {
      filter: brightness(75%);
    }

    /* Hides dropdown toggle arrow (left side) */
    .dropdown-toggle::before {
      display: none !important;
    }

    /* Dropdown styling */
    .dropdown-menu {
      color: $colorFont;
      background-color: $colorBase;
      border-radius: 0;
      margin: 0;
      padding: 0;
      box-shadow: 0px 0px 2px #1A1A1A;

      .dropdown-item {
        margin: 0;
        padding: .5rem 1rem .5rem 1rem;
        transition: background-color .25s linear;
      }

      .dropdown-item:hover {
        background-color: $colorAlternative;
      }

      .dropdown-item.active {
        background-color: $colorAlternative;
      }
    }

    /* Hide navbar texts and only show icons on medium size devices */
    @media only screen and (max-width: 1200px) {
      .nav-link {
        font-size: 0;
        transform-origin: left;
        transition: font-size .1s linear;
      }

      .nav-link:hover,
      .nav-link:focus {
        font-size: 1rem;
      }
    }

    /* Show text again because its within the menu dropdown */
    @media only screen and (max-width: 767px) {
      .nav-link {
        font-size: 1rem;
      }
    }
  } 

  /* Hide navbar brand on small devices */
  @media only screen and (max-width: 767px) {
    .navbar-brand {
      display: none;
    }
  }
}
