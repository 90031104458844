/* -------------------------------- IMPORTS -------------------------------- */
@import "../../../styles/variables";

/* ----------------------------- HEAT COMPONENT ---------------------------- */
.heat-component {
  table {
    background-color: $colorBase;

    thead tr th {
      color: $colorFont;
      border: none;
      vertical-align: middle;
    }

    tbody tr td {
      color: $colorFont;
      border-color: $colorAlternative;
      vertical-align: middle;
    }
  }

  .title img {
    max-width: 48px !important;
    max-height: 48px !important;
  }
}