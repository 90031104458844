
/* -------------------------------- IMPORTS -------------------------------- */
@import "../../styles/variables";

.make-component {
  input {
    color: $colorFont;
    background-color: $colorAlternative;
    border-style: none;
  }
  
  input:focus {
    outline: none;
  }
  
  .table-lite tbody tr:hover {
    background-color: transparent !important;
  }
  
  .form-group {
    margin-bottom: 0;
  }

  .form-group select {
    background-color: $colorAlternative;
  }
}
